.gdpr-model-popup {
    position: absolute;
    z-index: 5;
    top: 12%;
    left: 24%;
    font-family: robotoMedium;
}
.privacy-header {
    margin-left: 162px;
    margin-bottom: 2px;
    margin-top: 24px;
}
.information-text {
    margin-bottom: -5px;
    color: white;
    font-weight: normal;
    width: 90%;
    text-align: left;
    margin-left: 5%;
    font-size: 23px;
    line-height: 35px;
    text-shadow: 1px 1px 5px black, -1px 1px 5px black;
}


.GDPR-sprite-sheet-popup { width: 715px; height: 471px; background-position: -4px -4px; }
.GDPR-sprite-sheet-title { width: 379px; height: 52px; background-position: -4px -479px; }
.GDPR-sprite-sheet-btn-yes-active { width: 179px; height: 75px; background-position: -387px -479px; }
.GDPR-sprite-sheet-btn-yes-disable { width: 179px; height: 75px; background-position: -4px -535px; }
.GDPR-sprite-sheet-check-box { width: 41px; height: 47px; background-position: -187px -535px; }
.GDPR-sprite-sheet-tick-mark { width: 57px; height: 42px; background-position: -232px -535px; }

.buttonClass {
    position: absolute;
    width: 177px;
    height: 73px;
    bottom: 56px;
    left: 270px;
    border: none;
    background-color: transparent;
}
#buttonClassConfirmation {
    position: absolute;
    bottom: 43px;
    left: 270px;
}
.GDPR-sprite-sheet-btn-yes-active {
    cursor: pointer;
}
.GDPR-sprite-sheet-btn-yes-disable {
    cursor: not-allowed;
    pointer-events: none;
}
.link-text {
    color: #E9F963;
    font-size: 25px;
    text-decoration: underline;
    width: 100%;
    text-align: center;
    margin-top: 5px;
    text-shadow: 0 0 3px #091A87, 0 0 3px #091A87, 0 0 3px #091A87, 0 0 3px #091A87, 0 0 3px #091A87, 0 0 3px #091A87, 0 0 3px #091A87, 0 0 3px #091A87, 0 0 3px #091A87;
}
.gsn-about-link {
    text-decoration: underline;
    color: white;
    text-shadow: 1px 1px 1px black, -1px 1px 1px black;
}
.select-check-box {
    position: absolute;
    left: 185px;
    top: 9px;
    cursor: pointer;
}
.select-tick {
    position: absolute;
    left: 190px;
    top: 9px;
    z-index: 1;
    cursor: pointer;
}
.select-text {
    color: #fdf4ad;
    font-weight: normal;
    width: 85%;
    text-align: center;
    margin-left: 80px;
    font-size: 27px;
    line-height: 25px;
    text-shadow: 1px 2px 10px black, -1px 2px 10px black;
    padding-top: 20px;
}
.withdraw-link {
    color: white;
    position: absolute;
    bottom: 26px;
    left: 187px;
    font-size: 17px;
}
input:focus{
    outline: none;
}

@media screen and (max-width: 841px){
    @-ms-viewport { width: 320px; }
    .gdpr-model-popup {
        left: 3%;
    }
}

@media screen and (min-width: 841px) and (max-width: 880px){
    @-ms-viewport { width: 841px; }
    .gdpr-model-popup {
        left: 10%;
    }
}

@media screen and (min-width: 880px) and (max-width: 978px){
    @-ms-viewport { width: 880px; }
    .gdpr-model-popup {
        left: 13%;
    }
}
@media screen and (min-width: 978px) and (max-width: 1100px){
    @-ms-viewport { width: 978px; }
    .gdpr-model-popup {
        left: 16%;
    }
}
@media screen and (min-width: 1100px) and (max-width: 1173px){
    @-ms-viewport { width: 1100px; }
    .gdpr-model-popup {
        left: 19%;
    }
}
@media screen and (min-width: 1173px) and (max-width: 1401px){
    @-ms-viewport { width: 1173px; }
    .gdpr-model-popup {
        left: 21%;
    }
}

@media screen and (min-width: 1401px) and (max-width: 1586px){
    @-ms-viewport { width: 1401px; }
    .gdpr-model-popup {
        left: 25%;
    }
}

@media screen and (min-width: 1586px) and (max-width: 1760px){
    @-ms-viewport { width: 1586px; }
    .gdpr-model-popup {
        left: 27%;
    }
}

@media screen and (min-width: 1760px) and (max-width: 1960px){
    @-ms-viewport { width: 1760px; }
    .gdpr-model-popup {
        left: 29%;
    }
}

@media screen and (min-width: 1960px) and (max-width: 2230px){
    @-ms-viewport { width: 1960px; }
    .gdpr-model-popup {
        left: 31%;
    }
}
@media screen and (min-width: 2230px) and (max-width: 2640px){
    @-ms-viewport { width: 2230px; }
    .gdpr-model-popup {
        left: 34%;
    }
}

@media screen and (min-width: 2640px) and (max-width: 3082px){
    @-ms-viewport { width: 2640px; }
    .gdpr-model-popup {
        left: 35%;
    }
}

@media screen and (min-width: 3082px) and (max-width: 3174px){
    @-ms-viewport { width: 3082px; }
    .gdpr-model-popup {
        left: 39%;
    }
}

@media screen and (min-width: 3174px) and (max-width: 3219px){
    @-ms-viewport { width: 3082px; }
    .gdpr-model-popup {
        left: 39%;
    }
}

@media screen and (min-width: 3219px) and (max-width: 3520px){
    @-ms-viewport { width: 3219px; }
    .gdpr-model-popup {
        left: 39%;
    }
}

@media screen and (min-width: 3521px){
    @-ms-viewport { width: 3520px; }
    .gdpr-model-popup {
        left: 39%;
    }
}
