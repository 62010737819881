.fontfamily {
margin-top: 20px;
    font-family: 'barlow';
    color: black;
}
a { cursor: pointer; }

.headerArea{
    font-size: 2em;
    margin-bottom: 20px;
    width:700px;
    text-align: center;
    color: white;
    text-shadow: 1px 1px 2px black, 0 0 25px blue, 0 0 5px darkblue;
}

.preEle {
    border: none !important;
    background-color :transparent !important;
}

.tilesPreviewArea{
    height: 40px;
    width:750px;
    color: white;
}

.show {
    display: block !important;
    border: 2px solid gray;
    margin-bottom: 20px;
}

.block {
    display:none !important;
}

.marginLeft{
    margin-left: 0% !important;
}

.fullTileWithHeader{
    height: 436px;
    background-color: lightyellow;
    border: 1px solid gray;
    word-wrap: break-word;
}
.highlightJackpot {
    color: red;
    font-weight: bold;
}

.fullTile{
    height: 400px;
    width: 100%;
    background-color: yellow;
    border: 1px solid gray;
    word-wrap: break-word;
    overflow:auto;
    overflow-style:marquee,panner;
}

.halfTile1{
    height: 200px;
    width: 100%;
    background-color: rgb(252, 255, 224);
    border: 1px solid gray;
    word-wrap: break-word;
    overflow:auto;
    overflow-style:marquee,panner;
}

.halfTile2{
    height: 200px;
    width: 100%;
    background-color: rgb(199, 213, 165);
    border: 1px solid gray;
    word-wrap: break-word;
    overflow:auto;
    overflow-style:marquee,panner;
}

.blankTile{
    background-color:rgb(194, 135, 71);
}

.numberCircle {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    padding: 8px;
    background: #fff;
    border: 2px solid #666;
    color: #666;
    text-align: center;
    font: 12px Arial, sans-serif;
    position: relative;
    left: 41%;
}

.error{
    color: red;
    padding-left: 10px;
    background-color: #eee;
}
.dropdown {
    color: black;
    font-size: 18px;
    padding: 0.5%;
    margin-left: 1%;
    border-radius: 6px;
}

